
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";

export default defineComponent({
  name: "diagnostics-list",
  emits: ["new-selection-diagnostic"],
  components: {
    Datatable,
  },
  props: {
    selectable: { type: Boolean, default: true },
    finished: { type: Boolean, default: true },
  },
  setup(props, { emit }) {
    const store = useStore();

    let isReal = ref<boolean>(false);
    let diagnosticChoice = ref<number | null>();
    // store.getters.getDiagnostics[0].id
    let exploitationValid = ref<boolean>(false);

    const tableHeader = computed(() => {
      let header = [
        {
          name: "Action",
          key: "state",
          sortable: false,
        },
        {
          name: "Exploitation",
          key: "linkedExploitation",
          sortable: true,
        },
        {
          name: "Année culturale",
          key: "date_evaluated",
          sortable: true,
        },
        {
          name: "Cas",
          key: "status",
          sortable: false,
        },
        {
          name: "Résultat global",
          key: "score",
          sortable: true,
        },
      ];

      if (!props.finished) {
        header.pop();
        header.push({
          name: "Progrès",
          key: "score",
          sortable: true,
        });
      }

      if (props.selectable) {
        header.unshift({
          name: "",
          key: "radio",
          sortable: false,
        });
      }
      return header;
    });

    const getDiagnostics = computed(() => {
      return store.getters.getDiagnostics.filter(
        (diagnostic) => diagnostic.finished === props.finished
      );
    });

    const getExploitation = (exploitationId) => {
      let exploitations = store.getters.getExploitations;
      if (
        exploitations.some((exploitation) => exploitation.id === exploitationId)
      ) {
        return exploitations.find(
          (exploitation) => exploitation.id === exploitationId
        );
      }
      return false;
    };

    const getFactorsNumber = computed(() => {
      return store.getters.getFactors.length;
    });

    const getDiagnosticScore = (diagnosticId) => {
      let diagnostic = getDiagnostics.value.find(
        (diagnostic) => diagnostic.id === diagnosticId
      );
      let finalResult = 0;
      if (diagnostic)
        for (const result of diagnostic.results) {
          finalResult = finalResult + result.level_details.level;
        }
      // // todo: set correct divided by value
      return Math.round((finalResult / (5 * getFactorsNumber.value)) * 100);
    };

    const diagnosticChoiceEmit = (choiceId) => {
      diagnosticChoice.value = choiceId;
      emit("new-selection-diagnostic", diagnosticChoice.value);
    };

    return {
      isReal,
      diagnosticChoice,
      exploitationValid,
      tableHeader,
      diagnosticChoiceEmit,
      getDiagnostics,
      getExploitation,
      getDiagnosticScore,
      getFactorsNumber,
    };
  },
});
